<template>
    <div class="p-col-12 p-card relative page-container">
        <!-- <div class="p-d-flex">
            <breadcrumb :class="'p-col-12'" :home="home" :model="breadcrumb" />
        </div> -->
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loading">
            <img src="@/assets/img/spiner-loader.png" alt="loading" />
        </div>
        <!-- <TabMenu v-if="showTabs" :model="tabs" /> -->
        <router-view :key='$route.fullPath' :loading="loading" @loadingChange="loadingChange" />
    </div>
</template>

<script>
import { getOrganizationById } from '@/api/organization';
import { requestToastHandler } from '@/main/mixins';

export default {
    name: 'index',
    mixins: [requestToastHandler],
    data: () => ({
        organizationTitle: '',
        filters: {},
        home: { to: '/', icon: 'pi pi-home' },
        tabs: [
            {
                label: 'Список',
                to: '/organizations/list',
            },
            {
                label: 'Структура',
                to: '/organizations/structure',
            },
            {
                label: 'Теги',
                to: '/organizations/tags',
            },
            {
                label: 'Новые',
                to: '/organizations/newList',
            },
        ],
        loading: true,
    }),
    activated() {
        this.$emit('loadingChange')
    },
    computed: {
        orgId() {
            return this.$route.params.orgId;
        },
        breadcrumb() {
            const regExp = /:orgId/gi;
            const { orgId } = { ...this.$route.params };

            if (!this.$route.meta.breadcrumb) return [];
            return this.$route.meta.breadcrumb.map((breadcrumb) => {
                if (breadcrumb.to.indexOf(':orgId') >= 0) {
                    return {
                        ...breadcrumb,
                        label: this.organizationTitle || breadcrumb.label,
                        to: breadcrumb.to.replace(regExp, orgId),
                    };
                }
                return breadcrumb;
            });
        },
        showTabs() {
            return ['/organizations', '/organizations/list', '/organizations/structure', '/organizations/tags', '/organizations/newList'].includes(this.$route.path);
        },
        saveTabs() {
            return [
                `/organizations/${this.orgId}`,
                `/organizations/${this.orgId}/users`,
                `/organizations/${this.orgId}/branches`,
                `/organizations/${this.orgId}/tags`,
                `/organizations/${this.orgId}/forms`,
                `/organizations/${this.orgId}/changeHistory`,
                `/organizations/${this.orgId}/licenses`,
            ];
        },
    },
    methods: {
        async updateOrganizationTitle() {
            if (!this.orgId) return;

            try {
                let result = await getOrganizationById(this.orgId);
                const organisation = result.data;
                // console.log('organisation', organisation);
                if (!organisation) return;

                const name = organisation.attributes.fullName ? organisation.attributes.fullName : '';
                const isHead = organisation.relationships.parent.data == null ? ' (Головная)' : '(Филиал)';
                this.organizationTitle = name + isHead;
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        loadingChange(payload = false) {
            this.loading = payload;
        },
    },
    watch: {
        orgId(newId) {
            if (newId) {
                this.updateOrganizationTitle();
            }
        },
    },
    beforeRouteUpdate(to, from, next) {
        this.loading = true;
        if (!this.saveTabs.includes(to.fullPath)) this.organizationTitle = '';

        next();

        // если идем куда то кроме табов очищать
    },

    mounted() {
        if (this.orgId) {
            this.updateOrganizationTitle();
        }
    },
};
</script>

<style lang='scss' scoped>
.relative {
    position: relative;
}
.p-component-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.pi-spinner {
    font-size: 2rem;
}
</style>

